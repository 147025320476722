import React, { FC } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Button: FC<any> = ({
  LinkComponent,
  spacer,
  outerClass,
  buttonClass,
  buttonHref,
  buttonHrefClass,
  buttonLabel,
  buttonLine,
  linkProps,
}) => (
  <>
    {spacer ? <div className="div-block-39-copy" /> : null}
    <span className={`${outerClass}`}>
      <div className={buttonClass}>
        <div>
          <LinkComponent
            to={buttonHref}
            className={`${
              buttonHrefClass || "button-read-more button w-button"
            } ${buttonLine}`}
            {...linkProps}
          >
            {buttonLabel}
          </LinkComponent>
        </div>
      </div>
    </span>
  </>
);

export default Button;

Button.propTypes = {
  spacer: PropTypes.bool,
  outerClass: PropTypes.string,
  buttonClass: PropTypes.string,
  buttonHref: PropTypes.string,
  buttonHrefClass: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLine: PropTypes.string,
};

Button.defaultProps = {
  spacer: true,
  linkProps: {},
  LinkComponent: Link,
};
