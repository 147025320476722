import React from "react";
import { graphql } from "gatsby";
import HeroContainer from "../components/HeroContainer";
import Button from "../components/Button";
import SocialContainer from "../components/SocialContainer";
import SEO from "../components/SEO";
// import posed from "react-pose";

export const pageQuery = graphql`
  query HomePageSEO {
    prismicSeo(data: { name: { eq: "Mosano" } }) {
      data {
        name
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          url
        }
      }
    }
    prismicCookieConsent(uid: { eq: "cookie_consent" }) {
      data {
        setting_button_label
        accept_button_label
        decline_button_label
        close_button_label
        close_icon_alt
        title {
          text_1
          text_2
          text_3
        }
        cookies_categories {
          name
          details {
            text
          }
          policy_link {
            url
          }
          policy_link_text
          expand_button_label
          status
        }
      }
    }
  }
`;

// const LineHolder = posed.div({
//   enter: {
//     staggerChildren: 200
//   }
// });

// const Line = posed.div({
//   init: {
//     scale: 1,
//     rotate: "90deg",
//     opacity: 1
//   },
//   enter: {
//     opacity: 0.5,
//     scale: 1.5,
//     transition: {
//       type: "spring",
//       stiffness: 10,
//       damping: 0
//     }
//   }
// });

const Index = (props: any) => (
  <>
    <SEO {...props.data.prismicSeo.data} />
    <div className="hero-home">
      {/* <LineHolder className="div-block-37" initialPose="init" pose="enter">
        <Line className="line" />
        <Line className="line-2" />
        <Line className="line-3" />
        <Line className="line-4" />
        <Line className="line-5" />
        <Line className="line-6" />
        <Line className="line-7" />
        <Line className="line-8" />
        <Line className="line-9" />
        <Line className="line-10" />
      </LineHolder> */}

      <HeroContainer
        title="Are you looking to launch a new product and empower your industry?"
        paragraph="We provide software development services that give our clients the opportunity to grow their business."
        paragraphClass="p1"
      >
        <Button
          buttonClass="button-container home"
          buttonHref="/about"
          buttonLabel="discover how we do it"
          buttonLine="button-box"
        />
      </HeroContainer>
      <SocialContainer animation />
    </div>
  </>
);

export default Index;
